import './App.css';
import React, { Component } from 'react';

import $ from 'jquery';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Portfolio from './Components/Portfolio';
import ReactGA from 'react-ga4';
import Resume from './Components/Resume';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: 'bar',
      lang: 'En',
      resumeDataEn: {},
      resumeDataRo: {},
      resumeDataSp: {},
      resumeDataNo: {},
      resumeDataJa: {},
      dark: null,
    };
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.getData = this.getData.bind(this);

    ReactGA.initialize([
      {
        trackingId: 'G-GZMEW6NLF5',
      },
      {
        trackingId: 'GT-NNQRT5J',
      },
    ]);
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }

  getData(val) {
    // do not forget to bind getData in constructor
    this.setState({ dark: val });
    //console.log(this.state.dark);
  }

  handleChangeLanguage(e) {
    this.setState({ lang: e.target.value });
  }

  getResumeDataEn() {
    $.ajax({
      url: '/resumeDataEn.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        this.setState({ resumeDataEn: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  getResumeDataRo() {
    $.ajax({
      url: '/resumeDataRo.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        this.setState({ resumeDataRo: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }
  getResumeDataSp() {
    $.ajax({
      url: '/resumeDataSp.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        this.setState({ resumeDataSp: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }
  getResumeDataNo() {
    $.ajax({
      url: '/resumeDataNo.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        this.setState({ resumeDataNo: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  getResumeDataJa() {
    $.ajax({
      url: '/resumeDataJa.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        this.setState({ resumeDataJa: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getResumeDataEn();
    this.getResumeDataRo();
    this.getResumeDataSp();
    this.getResumeDataNo();
    this.getResumeDataJa();
  }

  getDataByLang(param) {
    switch (param) {
      case 'En':
        return this.state.resumeDataEn;
      case 'Ro':
        return this.state.resumeDataRo;
      case 'Sp':
        return this.state.resumeDataSp;
      case 'No':
        return this.state.resumeDataNo;
      case 'Ja':
        return this.state.resumeDataJa;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="App">
        <Header
          data={this.getDataByLang(this.state.lang).main}
          onChangeLanguage={this.handleChangeLanguage}
          lang={this.state.lang}
          sendData={this.getData}
        />
        <About
          data={this.getDataByLang(this.state.lang).main}
          isDarkTheme={this.state.dark}
        />
        <Resume
          data={this.getDataByLang(this.state.lang).resume}
          isDarkTheme={this.state.dark}
        />

        <Portfolio
          data={this.getDataByLang(this.state.lang).portfolio}
          isDarkTheme={this.state.dark}
        />
        <Contact
          data={this.getDataByLang(this.state.lang).main}
          isDarkTheme={this.state.dark}
        />
        <Footer
          data={this.getDataByLang(this.state.lang).main}
          isDarkTheme={this.state.dark}
        />
      </div>
    );
  }
}

export default App;
