import React, { Component } from 'react';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { showPhone: false, showEmail: false };
    this.showPhoneNumber = this.showPhoneNumber.bind(this);
    this.showEmailHandler = this.showEmailHandler.bind(this);
  }

  showPhoneNumber() {
    this.setState({ showPhone: true });
  }

  showEmailHandler() {
    this.setState({ showEmail: true });
  }
  
  render() {
    if (this.props.data) {
      var contactDetails = this.props.data.contactDetails;
      var contactLinks = this.props.data.contactLinks;
      var name2 = this.props.data.name2;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var message1 = this.props.data.contactmessage1;
      var message2 = this.props.data.contactmessage2;
      var message3 = this.props.data.contactmessage3;
      var chatSkype = this.props.data.chatSkype;
      var chatTelegram = this.props.data.chatTelegram;
      var chatEmail = this.props.data.chatEmail;
      var showMyEmail = this.props.data.showMyEmail;
      var showMyPhone = this.props.data.showMyPhone;
      var paddedText = this.props.isDarkTheme
        ? {            
            lineHeight: '1.7em',
            display: 'inline',
            background: '#ebeeee',
          }
        : {            
            lineHeight: '1.7em',
            display: 'inline',
            background: '#101a28', 
            fontWeight: 'bold'
          };
    }
    var theme = this.props.isDarkTheme ? 'dark' : 'white';

    return (
      <section
        id="contact"
        className="zig-zag"
        style={
          this.props.isDarkTheme
            ? { background: '#ebeeee url(../images/noise-bg-light.svg)' }
            : {
                background: '#101a28 url(../images/noise-bg-dark.svg)',
              }
        }
      >
        
        <div className="row section-head">
          <div className="twelve columns">
            <p className="contact-heading">
              <p style={paddedText} className={this.props.isDarkTheme ? 'contact-links-color-light' : 'contact-links-color-dark'}>{message1}</p>
              <br />
              <p style={paddedText} className={this.props.isDarkTheme ? 'contact-links-color-light' : 'contact-links-color-dark'}>{message2}
              <span style={{borderBottom: '3px solid #11abb0', textTransform: 'lowercase'}}>{contactLinks}</span>.</p>
              <br />
              <p style={paddedText} className={this.props.isDarkTheme ? 'contact-links-color-light' : 'contact-links-color-dark'}>{message3}
              <span style={{borderBottom: '3px solid #11abb0', textTransform: 'lowercase'}}>{contactDetails}</span>.</p>
              <br />
            </p>
          </div>
        </div>

        <div className="row contact-fix">
          <aside>
            <p
              className="title"
              style={
                this.props.isDarkTheme
                  ? {
                      color: '#111827',
                      display: 'inline',
                      background: '#ebeeee',
                      fontFamily: 'JetBrains Mono',
                      borderBottom: '3px solid #11abb0',
                    }
                  : {
                      color: 'white',
                      display: 'inline',
                      background: '#101a28',
                      fontFamily: 'JetBrains Mono',
                      borderBottom: '3px solid #11abb0',
                    }
              }
            >
              {contactLinks}:
            </p>
            <ul className="contact-links-logos">
              <li>
                <img
                  src="../../images/contact-logos/telegram-logo.png"
                  alt="telegram-logo"
                  width={28}
                  height={28}               
                />
                <a href="tg://resolve?domain=Alinsdev"
                  style={paddedText}
                  className={this.props.isDarkTheme ? 'contact-links-hovered-light' : 'contact-links-hovered-dark'}
                >
                  <span>{chatSkype}</span>
                  <svg className={this.props.isDarkTheme ? 'contact-links-arrow-light' : 'contact-links-arrow-dark'}
                  width="2.3rem" height="2.3rem" viewBox="0 0 20 20" aria-label="External social media link"><path d="M6 8h5.293L5 14.293l1.414 1.414 6.293-6.293V15h2V6H6v2z"></path></svg> 
                </a>
              </li>
              <li>
                <img
                  alt="skype-logo"
                  src="../../images/contact-logos/skype-logo.png"
                  width={28}
                  height={28}
                />
                <a
                  href="skype:live:.cid.3d5cb760873dc5ae?chat"
                  style={paddedText}
                  className={this.props.isDarkTheme ? 'contact-links-hovered-light' : 'contact-links-hovered-dark'}
                >
                  <span>{chatTelegram}</span>
                  <svg className={this.props.isDarkTheme ? 'contact-links-arrow-light' : 'contact-links-arrow-dark'}
                  width="2.3rem" height="2.3rem" viewBox="0 0 20 20" aria-label="External social media link"><path d="M6 8h5.293L5 14.293l1.414 1.414 6.293-6.293V15h2V6H6v2z"></path></svg> 
                </a>
              </li>
              <li>
                <img
                  alt="linkedin-logo"
                  src="../../images/contact-logos/linkedin-logo.png"
                  width={28}
                  height={28}
                />
                <a
                  href="https://www.linkedin.com/in/alin-sofianu-214b01288/"
                  style={paddedText} 
                  target="_blank"
                  className={this.props.isDarkTheme ? 'contact-links-hovered-light' : 'contact-links-hovered-dark'}
                >
                  <span>LinkedIn</span> 
                  <svg className={this.props.isDarkTheme ? 'contact-links-arrow-light' : 'contact-links-arrow-dark'}
                  width="2.3rem" height="2.3rem" viewBox="0 0 20 20" aria-label="External social media link"><path d="M6 8h5.293L5 14.293l1.414 1.414 6.293-6.293V15h2V6H6v2z"></path></svg> 
                </a>
              </li>
              <li>
                <img
                  alt="email-logo"
                  src="../../images/contact-logos/gmail.png"
                  width={28}
                  height={28}
                />
                <a href="mailto:alin.sofianu@gmail.com" 
                  style={paddedText}
                  className={this.props.isDarkTheme ? 'contact-links-hovered-light' : 'contact-links-hovered-dark'}>
                  <span>{chatEmail}</span>
                  <svg className={this.props.isDarkTheme ? 'contact-links-arrow-light' : 'contact-links-arrow-dark'}
                  width="2.3rem" height="2.3rem" viewBox="0 0 20 20" aria-label="External social media link"><path d="M6 8h5.293L5 14.293l1.414 1.414 6.293-6.293V15h2V6H6v2z"></path></svg> 
                </a>
              </li>
            </ul>
          </aside>
          <aside>
            <p
              className="title"
              style={
                this.props.isDarkTheme
                  ? {
                      color: '#111827',
                      display: 'inline',
                      background: '#ebeeee',
                      fontFamily: 'JetBrains Mono',
                      borderBottom: '3px solid #11abb0',
                    }
                  : {
                      color: 'white',
                      display: 'inline',
                      background: '#101a28',
                      fontFamily: 'JetBrains Mono',
                      borderBottom: '3px solid #11abb0',
                    }
              }
            >
              {contactDetails}:
            </p>
            <ul
              className="contact-details-logos"
              style={
                this.props.isDarkTheme
                  ? { color: '#111827' }
                  : { color: 'white' }
              }
            >
              <li>
                <img
                  alt="user-logo"
                  src={`../../images/contact-logos/user-${theme}.png`}
                  width={21}
                  height={21}
                />
                <span style={paddedText}>{name2}</span>
              </li>
              <li>
                <img
                  alt="location-logo"
                  src={`../../images/contact-logos/location-${theme}.png`}
                  width={21}
                  height={21}
                />
                <span style={paddedText}>
                  {city}, {state}
                </span>
              </li>
              <li>
                <img
                  alt="phone-logo"
                  src={`../../images/contact-logos/phone-${theme}.png`}
                  width={21}
                  height={21}
                />
                {!this.state.showPhone ? (
                  <span onClick={this.showPhoneNumber} style={buttonStyle}>
                    {showMyPhone}
                  </span>
                  ) : (
                    <span style={paddedText}>{phone}</span>
                  )}
              </li>
              <li>
                <img
                  alt="contact-logo"
                  src={`../../images/contact-logos/email-${theme}.png`}
                  width={21}
                  height={21}
                />
                {!this.state.showEmail ? (
                  <span onClick={this.showEmailHandler} style={buttonStyle}>
                    {showMyEmail}
                  </span>
                  ) : (
                    <span style={paddedText}>{email}</span>
                  )}
              </li>
            </ul>
          </aside>
        </div>
      </section>
    );
  }
}

const buttonStyle = {
  padding: "0 18px",
  backgroundColor: "#11ABB0",
  border: "none",
  cursor: "pointer",
  fontSize: "16px",
  clipPath: "polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%)",
  fontWeight: "bold"
};

export default Contact;
