import React, { Component } from 'react';
import './cards.css';

class Portfolio extends Component {
  render() {
    if (this.props.data) {
      var portfolioMessage = this.props.data.portfolioMessage;
      var isDarkTheme = this.props.isDarkTheme;

      var projects = this.props.data.projects.map(function (projects) {
        var projectImage = 'images/portfolio/' + projects.image;
        return (
          <div className="card" style={{ display: 'grid' }}>
            <a
              href={projects.url}
              style={{ display: 'grid' }}
              title={projects.title}
              target="_blank"
            >
              <img
                className="card__image"
                alt={projects.title}
                src={projectImage}
              />

              <div className="card__content">
                <span
                  className="title"
                  style={
                    isDarkTheme
                      ? { color: 'rgba(17, 24, 39, 1)', borderBottom: "2px solid rgba(17, 24, 39, 0.9)"}
                      : { color: 'white', borderBottom: "2px solid rgba(240, 96, 0, 0.9)", textShadow: 'rgba(17, 24, 39, 0.3) 1px 1px 1px' }
                  }
                >
                  {projects.title}
                </span>
                <p
                  style={
                    isDarkTheme
                      ? {
                          color: 'black',
                          opacity: '0.9',
                          lineHeight: '2.4rem',
                          margin: '0'
                        }
                      : { color: 'white', lineHeight: '2.4rem', margin: '0', textShadow: 'rgba(17, 24, 39, 0.3) 1px 1px 1px' }
                  }
                >
                  {projects.category}
                </p>
                <div className="card__arrow">
                  <svg
                    style={{
                      userSelect: 'none',
                      width: '100%',
                      height: '100%',
                      display: 'inline-block',
                      flexShrink: '0',
                      cursor: 'auto',
                    }}
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <circle opacity="0" r="96" cy="128" cx="128"></circle>
                      <circle
                        stroke-width="16"
                        stroke-miterlimit="10"
                        fill="none"
                        r="96"
                        cy="128"
                        cx="128"
                      ></circle>
                      <polyline
                        stroke-width="16"
                        stroke-linejoin="round"
                        stroke-linecap="round"
                        fill="none"
                        points="134.1 161.9 168 128 134.1 94.1"
                      ></polyline>
                      <line
                        stroke-width="16"
                        stroke-linejoin="round"
                        stroke-linecap="round"
                        fill="none"
                        y2="128"
                        x2="168"
                        y1="128"
                        x1="88"
                      ></line>
                    </g>
                  </svg>
                </div>
              </div>
            </a>
          </div>
        );
      });
    }
    return (
      <section
        id="portfolio"
        style={
          this.props.isDarkTheme
            ? { backgroundColor: '#57b3d1' }
            : { backgroundColor: '#1e293b' }
        }
      >
        <div>
          <h1
            className={this.props.isDarkTheme 
              ? 'padded-multi-line-light'
              : 'padded-multi-line-dark'
            }
            style={this.props.isDarkTheme
              ? {color: 'rgba(17, 24, 39, 0.9)'}
              : {color: 'rgba(255, 255, 255, 0.9)'}
            }
          >
            {portfolioMessage}
          </h1>
          <div className="cards-wrapper">{projects}</div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
