import React, { Component } from 'react';

class Resume extends Component {
  render() {
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var workTitle = this.props.data.workTitle;
      var skillTitle = this.props.data.skillTitle;
      var educationTitle = this.props.data.educationTitle;
      var hobbiesTitle = this.props.data.hobbiesTitle;
      var hobbies = this.props.data.hobbies;
      var isDarkTheme = this.props.isDarkTheme;

      var education = this.props.data.education.map(function (education) {
        return (
          <div key={education.school}>
            <p
              className="education-title"
              style={
                isDarkTheme
                  ? {
                      fontFamily: 'JetBrains Mono, monospace',
                      fontWeight: 'bold',
                    }
                  : {
                      color: 'white',
                      fontFamily: 'JetBrains Mono, monospace',
                      fontWeight: 'bold',
                    }
              }
            >
              {education.school}
            </p>
            <p
              className="info"
              style={{ fontFamily: 'proxima-nova, sans-serif' }}
            >
              {education.degree}
              <em className="date">{education.graduated}</em>
            </p>
            <p>{education.description}</p>
          </div>
        );
      });

      var work = this.props.data.work.map(function (work) {
        return (
          <div key={work.company}>
            <p
              style={
                isDarkTheme
                  ? {
                      fontSize: '22px',
                      fontWeight: 'bold',
                      lineHeight: '30px',
                      marginBottom: ' 10px',
                    }
                  : {
                      fontSize: '22px',
                      fontWeight: 'bold',
                      lineHeight: '30px',
                      marginBottom: ' 10px',
                      color: 'white',
                      fontFamily: 'JetBrains Mono, monospace',
                    }
              }
            >
              {work.company}
            </p>
            <p
              className="info"
              style={{ fontFamily: 'JetBrains Mono, monospace' }}
            >
              {work.title}
              <em
                style={{
                  fontFamily: 'JetBrains Mono, monospace',
                }}
                className="date"
              >
                {work.years}
              </em>
            </p>
            <p style={{ fontSize: '1.8rem' }}>{work.description}</p>
          </div>
        );
      });

      var skills = this.props.data.skills.map(function (skills) {
        var className = 'bar-expand ' + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className="logo"
                src={'images/tech-logos/' + skills.logo}
                alt={skills.name}
              />
              <em style={isDarkTheme ? null : { color: 'white' }}>
                {skills.name} @ {skills.level}
              </em>
              <span
                className={className}
                style={
                  isDarkTheme
                    ? { width: skills.level, borderRadius: '3px' }
                    : {
                        color: 'white',
                        width: skills.level,
                        background: '#0B757F',
                        borderRadius: '3px',
                      }
                }
              ></span>
            </div>
          </li>
        );
      });
    }

    return (
      <section
        id="resume"
        style={
          this.props.isDarkTheme
            ? null
            : { backgroundColor: '#101a28', color: 'white' }
        }
      >
        <div className="row work">
          <div className="columns header-col">
            <h1 style={this.props.isDarkTheme ? null : { color: 'white' }}>
              <span
                style={{
                  fontFamily: 'JetBrains Mono, monospace',
                  fontWeight: 'bold',
                }}
              >
                {workTitle}
              </span>
            </h1>
          </div>
          <div className="nine columns main-col">{work}</div>
        </div>

        <div className="row skill education">
          <div className=" columns header-col">
            <h1 style={this.props.isDarkTheme ? null : { color: 'white' }}>
              <span
                style={{
                  fontFamily: 'JetBrains Mono, monospace',
                  fontWeight: 'bold',
                }}
              >
                {skillTitle}
              </span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p style={{ fontSize: '1.8rem' }}>{skillmessage}</p>

            <div className="bars">
              <ul className="skills">{skills}</ul>
            </div>
          </div>
        </div>

        <div className="row education">
          <div className="columns header-col">
            <h1 style={this.props.isDarkTheme ? null : { color: 'white' }}>
              <span
                style={{
                  fontFamily: 'JetBrains Mono, monospace',
                  fontWeight: 'bold',
                }}
              >
                {educationTitle}
              </span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div
                className="twelve columns"
                style={{
                  fontSize: '1.8rem',
                }}
              >
                {education}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns header-col">
            <h1>
              <span
                style={
                  this.props.isDarkTheme
                    ? {
                        fontFamily: 'JetBrains Mono, monospace',
                        fontWeight: 'bold',
                      }
                    : {
                        color: 'white',
                        fontFamily: 'JetBrains Mono, monospace',
                        fontWeight: 'bold',
                      }
                }
              >
                {hobbiesTitle}
              </span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p className="info">
              <p style={{fontStyle: 'normal'}}>{hobbies}</p>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
